import React, { useState } from 'react'

import './PasswordReset.css'

const host = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_DRF_HOST : process.env.REACT_APP_PROD_DRF_HOST

function PasswordReset(props) {
    const [token,] = useState(props.token)
    const [pass, setPass] = useState(null)
    const [passCheck, setPassCheck] = useState(null)
    const [valid, setValid] = useState(false)
    const [responseMessage, setResponseMessage] = useState(null)
    const [done, setDone] = useState(false)

    const handlePassword = (e) => {

        if (e.target.value.length > 0) {
            setPass(e.target.value)
            if (e.target.value === passCheck) {
                setValid(true)
            } else {
                setValid(false)
            }
        } else {
            setValid(false)
        }
    }

    const handlePasswordCheck = (e) => {

        if (e.target.value.length > 0) {
            setPassCheck(e.target.value)
            if (e.target.value === pass) {
                setValid(true)
            } else {
                setValid(false)
            }
        } else {
            setValid(false)
        }

    }

    const submitForm = (e) => {
        e.preventDefault()

        if (valid) {


            const body = { token: token, password: pass }



            fetch(`${host}/users/user/password_reset/confirm/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },

                body: JSON.stringify(body),
            }).then(res => {
                if (res.ok) {
                    setDone(true)
                    return res.json()
                }
                return Promise.reject(res)
            })

                .catch(err => err.json())
                .catch((err) => {


                    if (err.password) {

                        setResponseMessage(err.password[0])
                    } else {
                        setResponseMessage('Erreur')
                    }
                }
                )

        }

    }


    return (
        <div id='form-container'>

            <img src={require('../assets/Frame.png')} alt='logo' style={{ width: 190 }} />
            <h2>Remise à zéro du mot de passe</h2>
            {done ?
                <h3>Remise à zéro terminée.</h3>
                :
                <form onSubmit={submitForm}>
                    <div id='input-container'>
                        <label>
                            Nouveau mot de passe:
                            <input type="password" name="password" onChange={handlePassword} className={valid ? 'valid-input' : 'invalid-input'} />
                        </label>

                    </div>

                    <div id='input-container'>
                        <label>
                            Vérification du mot de passe:
                            <input type="password" name="password-check" onChange={handlePasswordCheck} className={valid ? 'valid-input' : 'invalid-input'} />
                        </label>
                    </div>

                    {/* <input id='button' type="submit" value="Submit" /> */}
                    <button id={valid ? 'valid' : 'not-valid'} disabled={!valid} >Valider</button>
                </form>
            }

            {responseMessage ? <div>{responseMessage}</div> : null}
        </div>

    )
}

export default PasswordReset