import './App.css';

import PayPalPage from './components/PayPalPage';
import PasswordReset from './components/PasswordReset';
import ReactLogger from 'react-terminal-logger/console-logger'

const queryParams = new URLSearchParams(window.location.search);

const tokenParam = queryParams.get('token')


ReactLogger.start();
console.log('token', tokenParam)


function App() {

  return (
    <div className="App" style={{ display: 'flex', width: '100vw', height: '100vh', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column' }}>

      {tokenParam ? <PasswordReset token={tokenParam} /> : <PayPalPage />}


    </div>
  );
}


export default App;
