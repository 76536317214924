import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>
    <App style={{
      Display: 'flex',
      width: '100vw',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh'
    }} />

  </React.Fragment>
);


