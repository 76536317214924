import React from 'react'
import ReactDOM from 'react-dom';
import Button from './Button';

const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });

const location = window.location.href


const produit = location.split('?')
export default function PayPalPage() {
    console.log('Produit', produit)

    function _createOrder(data, actions) {

        return actions.order.create({
            purchase_units: [
                {
                    description: produit[2],
                    amount: {
                        value: produit[1],
                    },

                },
            ],
        });
    }

    async function _onApprove(data, actions) {
        let order = await actions.order.capture();

        console.log(order);
        window.ReactNativeWebView &&
            window.ReactNativeWebView.postMessage(JSON.stringify(order));
        return order;
    }

    function _onError(err) {
        let errObj = {
            err: err,
            status: "FAILED",
        };
        window.ReactNativeWebView &&
            window.ReactNativeWebView.postMessage(JSON.stringify(errObj));
    }

    const onCancel = () => {
        window.ReactNativeWebView.postMessage('cancel')
    }


    return (
        <>
            <div style={{ width: '80%', borderWidth: 1, borderStyle: 'solid', borderColor: 'lightgray', display: 'flex', marginTop: 50, marginBottom: 50, flexDirection: 'column', borderRadius: 5 }}>


                <h2>{produit[2].charAt(0).toUpperCase()}{produit[2].slice(1)}</h2>

                <h3>Prix : {produit[1]} EUR</h3>




            </div>

            <div style={{ width: '80%', height: '100%', justifyContent: 'space-around', alignItems: 'center' }}>
                <PayPalButton
                    createOrder={(data, actions) => _createOrder(data, actions)}
                    onApprove={(data, actions) => _onApprove(data, actions)}
                    onCancel={() => _onError("Canceled")}
                />

                <Button onCancel={onCancel} />

            </div>

        </>

    )
}
