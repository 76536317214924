import React from 'react'

function Button({ onCancel }) {
    return (
        <div >
            <button style={{ fontSize: 15 }} onClick={onCancel}>Annuler</button>
        </div>
    )
}

export default Button